import * as React from "react"
import Layout from "../components/layout"
import Head from "../components/head"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"

const ContactPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
        }
      }
    }
  `)

  const contactMethods = [
    { name: "LinkedIn", url: "https://www.linkedin.com/in/lasserravn/" },
    { name: "Twitter", url: "https://twitter.com/lasserravn/" },
  ]

  const schema = {
    "@context": "https://schema.org/",
    "@type": "Person",
    name: "Lasse Rosendahl Ravn",
    url: "https://lssrvn.com",
    image: data.site.siteMetadata.image,
    sameAs: [
      "https://www.linkedin.com/in/lasserravn/",
      "https://lssrvn.com",
      "https://twitter.com/lssrvn1",
    ],
    jobTitle: "Product Manager",
    worksFor: {
      "@type": "Organization",
      name: "Truestory Aps",
    },
  }

  return (
    <Layout>
      <div className="my-4 sm:my-12 px-4">
        <Head
          title={`Contact | ${data.site.siteMetadata.author}`}
          schema={schema}
        />
        <h1 className="text-zinc-100 text-5xl font-bold mb-4">
          {" "}
          Reach out
          <span role="img" aria-label="emoji">
            🙋‍♂️
          </span>
        </h1>
        <p className="text-zinc-400 text-lg mb-4">
          If you have questions about something on the site or just want to
          reach out, feel free to use one of the following:
        </p>
        <ul className="list-disc pl-3">
          {contactMethods.map(edge => {
            return (
              <li className="underline">
                <Link
                  className="rounded bg-zinc-800 text-zinc-400"
                  to={edge.url}
                >
                  {edge.name}
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    </Layout>
  )
}

export default ContactPage
